@import "../bootstrap-variables";

/*
  Default colors for SMB
 */
$dark-blue: #1a222c;
$primary-blue: $blue;
$primary-color: $teal;
$secondary-blue: #002c59;
$light-blue: #0074c9;
$dark-grey: rgba($dark-blue, 0.5);
$light-grey: #bbbbbb;
$lightest-grey: #e0e0e0;
$lightest-grey2: #f8f8f8;
$white: #ffffff;
$system-red: #ff1800;
$system-green: #00c696;
$system-yellow: #fdbd1e;
$grey-hover: rgba(26, 34, 44, 0.1);

$app-header-height: 64px !default;
$tab-nav-height: 42px !default;
$sidebar-width: 64px !default;
$app-height: calc(var(--vh, 1vh) * 100);
$background-color: #fcfcfd;

/*
  text colors
 */
.color-dark-blue {
  color: $dark-blue;
}

.color-primary {
  color: $primary-color !important;;
}

.color-secondary {
  color: $secondary-blue !important;;
}

.color-light-blue {
  color: $light-blue;
}

.color-dark-grey {
  color: $dark-grey;
}

.color-light-grey {
  color: $light-grey;
}

.color-lightest-grey {
  color: $lightest-grey;
}

.color-grey-hover {
  color: $grey-hover;
}

.color-white {
  color: $white;
}

.color-red, .color-danger {
  color: $system-red !important;;
}

.color-green, .color-success {
  color: $system-green !important;;
}

.color-yellow, .color-warning {
  color: $system-yellow !important;;
}

.color-hover {
  color: $grey-hover;
}

/*
  background colors
 */

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-secondary {
  background-color: $secondary-blue !important;
}

.bg-light-blue {
  background-color: $light-blue;
}

.bg-dark-grey {
  background-color: $dark-grey;
}

.bg-light-grey {
  background-color: $light-grey;
}

.bg-lightest-grey {
  background-color: $lightest-grey;
}

.bg-grey-hover {
  background-color: $grey-hover;
}

.bg-white {
  background-color: $white;
}

.bg-red, .bg-danger {
  background-color: $system-red !important;;
}

.bg-green, .bg-success {
  background-color: $system-green !important;;
}

.bg-yellow, .bg-warning {
  background-color: $system-yellow !important;
}

.bg-hover {
  background-color: $grey-hover;
}

/*
  border radius
*/
.border-radius-10 {
  border-radius: 10%;
}

.border-radius-20 {
  border-radius: 20%;
}

.border-radius-30 {
  border-radius: 30%;
}

.border-radius-40 {
  border-radius: 40%;
}

.border-radius-50 {
  border-radius: 50%;
}

/*
  border size
 */
.border {
  border: 1px solid !important;
}

.border-lg {
  border: 2px solid !important;
}

.border-xl {
  border: 3px solid !important;
}

/*
  border colors
 */
.border-dark-blue {
  border-color: $dark-blue !important;
}

.border-primary {
  border-color: $primary-color !important;
}

.border-secondary {
  border-color: $secondary-blue !important;
}

.border-light-blue {
  border-color: $light-blue !important;
}

.border-dark-grey {
  border-color: $dark-grey !important;
}

.border-light-grey {
  border-color: $light-grey !important;
}

.border-lightest-grey {
  border-color: $lightest-grey !important;
}

.border-lightest-grey2 {
  border-color: $lightest-grey2 !important;
}

.border-grey-hover {
  border-color: $grey-hover !important;
}

.border-white {
  border-color: $white !important;
}

.border-red, .border-danger {
  border-color: $system-red !important;;
}

.border-green, .border-success {
  border-color: $system-green !important;;
}

.border-yellow, .border-warning {
  border-color: $system-yellow !important;
}

.border-grey-hover {
  border-color: $grey-hover !important;
}

/*
  box shadow
 */
.box-shadow-10 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}

.box-shadow-50 {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
}

/*
notification status
 */

.status-success {
  font-size: 10px;
  font-weight: bold;
  background-color: $system-green;
  color: $white;
  border-radius: 4px;
  padding: 4px 8px;
  height: fit-content;
}

.status-error {
  font-size: 10px;
  font-weight: bold;
  background-color: $system-red;
  color: $white;
  border-radius: 4px;
  padding: 4px 8px;
  height: fit-content;
}
