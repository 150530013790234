.icon-button {
  padding: 4px;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  border-radius: 50%;
  border: none;
  background: transparent;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $dark-grey, $dark-grey);
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: scale(0, 0);
    border: none;
  }

  &:hover:after {
    width: 100%;
    height: 100%;
    opacity: .3;
    transform: scale(1, 1);
    transition: transform 0.3s;
    border: none;
  }

  &:active:after {
    opacity: 0.1;
    border: none;
  }
}
