@import 'custom/default-variable';
@import 'custom/button';

@font-face {
  font-family: 'OpenSans';
  src: url('fonts/OpenSans-Regular.ttf'); /* IE9 Compat Modes */
  src: url('fonts/OpenSans-Regular.ttf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/OpenSans-Regular.ttf') format('woff2'), /* Super Modern Browsers */
  url('fonts/OpenSans-Regular.ttf') format('woff'), /* Pretty Modern Browsers */
  url('fonts/OpenSans-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/OpenSans-Regular.ttf#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Roboto';
  src: url('fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes */
  src: url('fonts/Roboto-Regular.ttf?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/Roboto-Regular.ttf') format('woff2'), /* Super Modern Browsers */
  url('fonts/Roboto-Regular.ttf') format('woff'), /* Pretty Modern Browsers */
  url('fonts/Roboto-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/Roboto-Regular.ttf#svgFontName') format('svg'); /* Legacy iOS */
  font-weight: normal;
  font-style: normal;

}

body {
  font-family: Arial, OpenSans, Roboto, sans-serif;
  background-color: $background-color;
}

a, a:hover {
  color: $primary-blue;
}

blockquote {
  padding: 8px;
  background: #eee;
  border: 1px solid #eee;
  word-break: break-all;
  border-radius: 3px;
}

.main {
  margin-top: 15px;
}

/**
Override react-redux-toastr css
 */
.toastr {
  .rrt-left-container, .rrt-right-container {
    display: none;
  }

  .rrt-middle-container {
    margin-left: 15px !important;
  }
}