// Bootstrap overrides

//
// Color system
//

$white: #fff;
$gray-100: #f0f3f5;
$gray-200: #c2cfd6;
$gray-300: #a4b7c1;
$gray-400: #869fac;
$gray-500: #678898;
$gray-600: #536c79;
$gray-700: #3e515b;
$gray-800: #29363d;
$gray-900: #151b1e;
$black: #000 !default;

//$blue:                            #20a8d8;
$blue: #346cb0;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #f86c6b;
$orange: #f8cb00;
$yellow: #ffc107 !default;
$green: #4dbd74;
//$teal: #20c997 !default;
$teal: #339999 !default;
$cyan: #63c2de;

$colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800
);

$theme-colors: (
  primary: $teal,
  secondary: $gray-300,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions: true;
$enable-rounded: false;

// Settings for the `<body>` element.

$body-bg: #e4e5e6;

$font-size-base: 0.875rem;